<template>
  <div>
    <CostCard />
    <v-card
      color="element"
      class="mx-auto my-12"
      max-width="640"
    >
      <v-card-text>
        <v-card color="backgroundLight" class="rounded-lg">
          <v-card-text>
            <span class="primary--text" v-html="$t('subscription.signing.desc')"></span>
          </v-card-text>
        </v-card>
        <SignDocumentTemplate
          v-for="(docItem, index) in documents"
          :key="index"
          :documentKey="docItem"
          class="mt-10"
          divider
          isSubscription
          :asset="product"
          :maxWidth="640"
          @accepted="makeDocAccepted($event, index)"
        />
        <v-card color="transparent" class="mt-9 px-2" elevation="0">
          <h2 class="font-weight-medium">
            {{ $t('subscription.signing.footer.title') }}
          </h2>
          <div class="mt-3">
            {{ $t('subscription.signing.footer.desc') }}
          </div>
        </v-card>
        <div class="mt-15">
          <CardNav
            id="signing-card-nav"
            :nextText="$t('subscription.navigation.sign')"
            :prevText="$t('subscription.navigation.back')"
            @next="next"
            @previous="previousStep(1)"
            :prevDisabled="loading"
            :nextDisabled="disabled"
            :nextLoading="loading"
            :vertical="$vuetify.breakpoint.smAndDown"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import CardNav from '@/components/Shared/CardNav.vue';

export default {
  name: 'Documents',
  components: {
    SignDocumentTemplate: lazyLoad('components/Documents/SignDocumentTemplate'),
    CostCard: lazyLoad('components/Subscription/CostCard'),
    CardNav,
  },
  data() {
    return {
      disabled: true,
      document: {},
      loading: false,
      documents: [
        'contract',
        'risks',
        'costs_and_benefits',
        'key_information_document',
        'emission_terms',
        'effecta',
      ],
      docs: [
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    };
  },
  computed: {
    ...mapGetters('assets', {
      products: 'all',
    }),
    product() {
      return this.products.find((item) => item.id === this.$route.params.product);
    },
  },
  methods: {
    ...mapActions('subscription', [
      'previousStep',
      'nextStep',
      'createSubscriptionOrder',
    ]),
    makeDocAccepted(event, index) {
      this.docs[index] = event;

      if (this.docs.every((doc) => doc)) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    async next() {
      this.loading = true;
      try {
        await this.createSubscriptionOrder();
      } catch (error) {
        this.$notify.error(error.message);
        this.loading = false;
        return;
      }
      this.nextStep(1);
      this.loading = false;
    },
  },
};
</script>
